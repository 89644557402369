import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import GStore from "@/store";
import 'nprogress/nprogress.css'
// import upperFirst from "lodash/upperCase"
// import camelCase from "lodash/camelCase"

// const requireComponent = require.context(
//     './components',
//     false,
//     /Base[A-Z]\w+\.(vue|js)$/
// )
 const app = createApp(App)
// requireComponent.keys().forEach(fileName =>
// {
//     const componentConfig = requireComponent(fileName)

//     const componentName = upperFirst(
//         camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
//     )

//     app.component(componentName, componentConfig.default || componentConfig)
// })
//createApp(App).use(store).use(router)

app
//.use(store)
.use(router)
.provide('GStore', GStore)
.mount("#app");
